<template>
  <div class="main">
    <div class="title">3d模型信息</div>
    <!-- 查询 -->
    <div class="search">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        label-width="80px"
      >
        <!-- <el-form-item label="设备名称：">
          <el-select
            v-model="formData.deviceId"
            filterable
            placeholder="请选择绑定的设备"
            class="select-project"
            @change="changeProject($event)"
            clearable
          >
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
              style="color: rgb(195, 226, 249)"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="设备id：" >
          <el-input v-model="formData.deviceId" clearable placeholder="请输入设备id"></el-input>
        </el-form-item>
        <!-- <el-form-item label="3d图url：" >
          <el-input v-model="formData.url" clearable placeholder="请输入3d图url"></el-input>
        </el-form-item> -->
      </el-form>
      <el-button type="primary" style="height: 40px" @click="onSubmit"
        >查询</el-button
      >
    </div>
    <!-- 表格部分 -->
    <div class="table">
      <div class="table-header">
        <p class="table-header-title">3d模型信息列表</p>
        <el-button type="primary" @click="addDevice">新增3d模型</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
        style="width: 100%; color: rgba(0, 0, 0, 0.85)"
      >
        <el-table-column type="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column
          label="设备id"
          show-overflow-tooltip
          align="center"
          width="200"
          prop="deviceId"
        >
        </el-table-column>
        <el-table-column
          prop="url"
          label="图形url"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>

        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              disabled
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
          style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          "
        >
        </el-pagination>
      </div>
    </div>
    <!-- 新增/编辑设备弹框 -->
    <el-dialog
      :title="isEdit ? '编辑3d图' : '新增3d图'"
      :visible.sync="dialogFormVisible"
      :before-close="closeExpertFormDialog"
      width="700px"
    >
      <el-form :model="form" label-width="145px" ref="form">
        <el-form-item label="绑定设备">
          <!-- <el-input v-model="form.deviceId" autocomplete="off"></el-input> -->
          <el-select
            v-model="form.deviceId"
            filterable
            placeholder="请选择绑定的设备"
            class="select-project"
            @change="changeProject($event)"
            style="width: 515px"
          >
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
              style="color: rgb(195, 226, 249)"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="3d图上传">
          <el-upload
            action="https://test-api.zijingshuke.com/baixiang/base/aly/oss/upload"
            :headers="headers"
            :on-success="handleSuccess"
            :on-error="handleError"
            :before-upload="beforeUpload"
            accept=".glb"
          >
            <el-button type="primary">点击上传文件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="3d图url：">
          <el-input disabled v-model="form.url" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="3d图url：" v-if="this.isEdit === true">
          <el-input  v-model="form.url" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogCancel()">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
    
    <script>
export default {
  inject: ["reload"],
  data() {
    return {
   
      headers: { token: localStorage.getItem("token") },
      formData: {
        deviceId: "",
        url: "",
      },
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      tableData: [],
      tableDatas: [],
      dialogFormVisible: false,
      isEdit: false, //添加和编辑 默认添加
      form: {
        // 设备id
        deviceId: "",
        url: "",
      },
      deviceList: [],
    };
  },
  created(){
    this.listRequest();
  },
  mounted() {
   
    this.deviceIDRequest();
    this.watchStorage()
  },
  methods: {
    // 上传成功事件
    handleSuccess(response, file, fileList) {
      // 文件上传成功后的处理逻辑
      var that = this;
      console.log(response.data);
      this.$message({
        message: "上传成功!",
        type: "success",
        duration: 1000,
      });
      this.$nextTick(() => {
        // @ts-ignore
        that.form.url = response.data;
      });
      console.log(that.form.url);
    },
    // 上传失败事件
    handleError(error, file, fileList) {
      this.$message({
        message: "上传失败!",
        type: "error",
        duration: 1000,
      });
      // 文件上传失败后的处理逻辑
      console.error("文件上传失败", error);
    },

    beforeUpload(file) {
      // 在上传前进行一些校验
      console.log("上传前校验", file);
    },
     // 监控locaStorage
     watchStorage() {
      const that = this;
      window.addEventListener("setItemEvent", function (e) {
        // 监听setitem的 key ，执行对应的业务逻辑
        // console.log(e.key, e.value);
        if (e.key === "projectID") {
          const newProjectId = e.value;
          that.listRequest(newProjectId);
        }
      });
    },
    // 请求3d列表接口
    listRequest(id) {
      let projectId
      let projectID = localStorage.getItem("projectID");
      if(id){
        projectId = id
      }else{
        projectId = projectID
      }
      var that = this;
      let param = {
        pageNum: that.currentPage,
        pageSize: that.pageSize,
        deviceId: that.formData.deviceId,
        url: that.formData.url,
        projectId:projectId
      };
      this.$post("/device3dPhoto/pageList", param)
        .then((res) => {
          if (res.code == 1) {
            // console.log(res.data);
            this.tableData = res.data.list;
            // 比较projectID
            this.tableDatas = this.tableData.filter(item => item.projectId == projectId);
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取设备列表
    deviceIDRequest() {
      let param = {
        deviceState: 0,
        projectList: [localStorage.getItem("projectID")],
      };
    //   console.log(param)
      this.$post("/device/list", param)
        .then((res) => {
          if (res.code == 1) {
            // console.log(res.data);
            this.deviceList = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    changeProject(value) {
      console.log(value);
      console.log(this.form.deviceId);
    },

    // 查询
    onSubmit() {
      this.listRequest();
    },
    // 新增设备
    addDevice() {
      // @ts-ignore
      this.form = {url:'请点击上传文件按钮获取'};
      // this.form = {};
      this.dialogSelectedOptions = [];
      this.dialogFormVisible = true;
      console.log("add");
      this.isEdit = false;
      this.deviceIDRequest();
    },
    //编辑按钮
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.deviceIDRequest();
      this.$nextTick(() => {
        //赋值
        this.form = Object.assign({}, row);
      });
    },
    //删除按钮
    handleDelete(index, row) {
      // console.log(row);
      let param = {
        id: row.id,
      };
      this.$get("/device3dPhoto/del", param)
        .then((response) => {
          if (response.code === "1") {
            this.$message({
              message: "删除3d模型成功!",
              type: "success",
              duration: 1000,
            });
            // 刷新整个页面
            this.reload();
          } else {
            this.$message({
              message: response.msg,
              type: "warning",
              duration: 1000,
            });
          }
        })
        .catch((error) => {
          this.$message({
            message: error.msg,
            type: "error",
            duration: 1000,
          });
        });
    },

    //关闭dialog前
    closeExpertFormDialog() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框取消按钮
    handleDialogCancel() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框确认按钮
    handleDialogConfirm() {
      console.log(this.form.url);
      if (
        this.form.deviceId == null ||
        this.form.url == null ||
        this.form.deviceId == "" ||
        this.form.url == ""
      ) {
        this.$message({
          message: "请填写完整信息!",
          type: "warning",
          duration: 1000,
        });
      } else {
        console.log("22");
        if (this.isEdit) {
          let param = {
            // 设备id
            deviceId: this.form.deviceId,
            id: this.form.id,
            url: this.form.url,
            projectId:localStorage.getItem("projectID")
          };
          this.$post("/device3dPhoto/saveOrUpdate", param)
            .then((response) => {
              if (response.code === "1") {
                this.$message({
                  message: "编辑3d图成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                this.dialogFormVisible = false;
                this.$refs["form"].resetFields();
                this.dialogSelectedOptions = [];
              } else {
                this.$message({
                  message: response.msg,
                  type: "warning",
                  duration: 1000,
                });
                this.dialogSelectedOptions = [];
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
        } else {
          let param = {
            deviceId: this.form.deviceId,
            url: this.form.url,
            projectId:localStorage.getItem("projectID")
          };
          this.$post("/device3dPhoto/saveOrUpdate", param)
            .then((response) => {
              if (response.code === "1") {
                this.$message({
                  message: "添加3d图成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                this.dialogFormVisible = false;
                this.$refs["form"].resetFields();
              } else {
                this.$message({
                  message: response.msg,
                  type: "warning",
                  duration: 1000,
                });
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
        }
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs["form"].resetFields();
        });
        this.dialogFormVisible = false;
        this.isEdit = false;
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.listRequest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.listRequest();
    },
  },
};
</script>
    <style>
.el-upload-list__item-name,
.el-upload-list__item-status-label {
  display: none !important;
}
</style>
    <style lang="scss" scoped>
.main {
  margin: 10px;
  .search {
    margin: 20px auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    // padding-top:20px ;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 10px;
    box-sizing: border-box;
    .el-form {
      display: flex;
      // justify-content: space-between;
      margin-top: 20px;
      .el-form-item {
        // margin-left: 10px;
        display: flex;
        flex-wrap: wrap;

        /deep/ .el-form-item__content {
          vertical-align: middle !important;
          width: 180px !important;
        }
        /deep/ .el-form-item__label {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }
    }
  }
  .table {
    margin: 10px 0;
    background-color: #fff;
    padding: 10px;
    .table-header {
      display: flex;
      justify-content: space-between;
      // margin: 20px 10px;
      padding: 10px 0px 0px;
      .table-header-title {
        padding: 10px 0px 0px;
      }
    }
    .el-table {
      margin: 20px auto;
    }
  }
}
</style>
    